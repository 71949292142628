import { Box, Typography } from "@mui/material";
import React from "react";

import ConnectPhantomButton from "components/common/ConnectPhantomButton";
import useCheckMobileScreen from "lib/hooks/useCheckMobileScreen";
import DownloadPhantomButton from "components/common/DownloadPhantomButton";

import {
  container,
  paperContainer,
  paperTextContent,
  paperTextTitle,
  textStyle,
  title,
} from "./styles";

const CRConnectWallet = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <Box sx={container}>
      <Typography
        sx={title}
        variant="h2"
      >{`Welcome Home, Stranger`}</Typography>

      <Typography variant="h3" sx={textStyle}>
        {`We’re Hellbenders Motorcycle Club, an outlaw biker gang of amphibious superheroes on an epic adventure beyond time, space and the death-grip of global civilization.
`}
      </Typography>
      <Typography variant="h3" sx={textStyle}>
        {`There are no rules to follow here. No terms of service. No privacy statements. There’s not even any intellectual f*cking property.`}
      </Typography>
      <Typography variant="h3" sx={textStyle}>
        {`Here in the Slipstream, you’ll receive guidance from many different signs and portents, including this one:`}
      </Typography>

      <Box sx={paperContainer}>
        <Typography sx={paperTextTitle}>{`Outlaw Code`}</Typography>

        <Typography sx={paperTextContent}>{`Believe Nothing.`}</Typography>

        <Typography
          sx={paperTextContent}
        >{`Experience Everything.`}</Typography>

        <Typography sx={paperTextContent}>{`Deceive No One.`}</Typography>
      </Box>

      <Typography variant="h3" sx={{ ...textStyle, marginBottom: "2vmax" }}>
        {`To enter the Slipstream:`}
      </Typography>

      <ConnectPhantomButton />
      {isMobile && <DownloadPhantomButton sx={{ marginTop: "1vmax" }} />}
    </Box>
  );
};

export default CRConnectWallet;
