import { SxProps } from "@mui/material";

export const container: SxProps = {
  height: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  background: `url(/hellbenders_bar_bg.png)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
