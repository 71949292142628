import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useAtom } from "jotai";
import * as React from "react";

import UserCard from "components/common/UserCard/UserCard";
import FilterBar from "components/common/FilterBar/FilterBar";
import HellbendersDescription from "components/common/HellbendersDescription";
import { getLeaderboardUsers } from "lib/axios/requests/users/getLeaderboardUsers";
import { searchTextFilter, selectedSortFilter } from "lib/store/filters";
import {
  allLeaderboardUsers,
  currentUser,
  currentWallet,
  filteredLeaderboardUsers,
  isLoadingUser,
  leaderboardLoading,
  userHasNoID,
} from "lib/store";

import {
  cardsContainer,
  contentContainerStyles,
  descriptionWrapper,
} from "./styles";

const ITEMS_PER_PAGE = 15;

export const LeaderboardContent = () => {
  const fetchingRef = React.useRef(false);
  const [wallet] = useAtom(currentWallet);
  const [user] = useAtom(currentUser);
  const [missingID] = useAtom(userHasNoID);
  const [loading] = useAtom(leaderboardLoading);
  const [searchText] = useAtom(searchTextFilter);
  const [selectedSort] = useAtom(selectedSortFilter);
  const [loadingUser] = useAtom(isLoadingUser);
  const [allUsers, setAllUsers] = useAtom(allLeaderboardUsers);
  const [filteredUsers, setFilteredUsers] = useAtom(filteredLeaderboardUsers);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [finishedPaginate, setFinishedPaginate] = React.useState(false);

  const executeBroodSearch = React.useCallback(
    async (page: number) => {
      if (!fetchingRef.current) {
        fetchingRef.current = true;

        const users = await getLeaderboardUsers(
          page * ITEMS_PER_PAGE,
          ITEMS_PER_PAGE,
          searchText,
          selectedSort.name,
          selectedSort.value
        );

        if (users.length === 0) setFinishedPaginate(true);

        setCurrentPage(page + 1);
        fetchingRef.current = false;
        return users;
      }
      return [];
    },
    // eslint-disable-next-line
    [selectedSort, searchText]
  );

  const paginate = React.useCallback(async () => {
    const users = await executeBroodSearch(currentPage);
    setAllUsers(prevUsers => [...prevUsers, ...users]);
    setFilteredUsers(prevUsers => [...prevUsers, ...users]);
    // eslint-disable-next-line
  }, [executeBroodSearch, currentPage]);

  const filterUsers = React.useCallback(async () => {
    setFinishedPaginate(false);
    const users = await executeBroodSearch(0);
    setAllUsers([...users]);
    setFilteredUsers([...users]);
    // eslint-disable-next-line
  }, [executeBroodSearch]);

  React.useEffect(() => {
    filterUsers();
    // eslint-disable-next-line
  }, [searchText, selectedSort]);

  const renderComponent = () => {
    if (wallet !== "" && !missingID) {
      return (
        <Box sx={{ flex: 1 }}>
          <Box sx={descriptionWrapper}>
            <Typography
              variant="h2"
              sx={{ fontFamily: "Patched", fontWeight: "900" }}
            >{`Welcome Home, ${user.username}`}</Typography>

            <HellbendersDescription />
          </Box>
          <FilterBar allUsers={allUsers} setFilteredUsers={setFilteredUsers} />
          <Box>
            <Grid sx={cardsContainer} container alignItems="stretch">
              {filteredUsers.map((val, index) => (
                <UserCard
                  paginate={paginate}
                  isLast={
                    filteredUsers.length - 1 === index && !finishedPaginate
                  }
                  key={`${val._id}-${index}`}
                  {...val}
                  isBroodLeader={false}
                />
              ))}
            </Grid>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box sx={contentContainerStyles}>
      {loading ? (
        <CircularProgress sx={{ alignSelf: "center" }} />
      ) : (
        renderComponent()
      )}
    </Box>
  );
};
