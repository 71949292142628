import { Box } from "@mui/material";
import * as React from "react";
import { currentWallet, userHasNoID } from "lib/store";
import { useAtom } from "jotai";

import { container } from "./styles";
import { CRWalletConnected } from "./CRWalletConnected/CRWalletConnected";
import CRConnectWallet from "./CRConnectWallet/CRConnectWallet";

import { LeaderboardContent } from "../LeaderboardContent/LeaderboardContent";

const Quest = () => {
  const [publicKey] = useAtom(currentWallet);

  const [missingID] = useAtom(userHasNoID);

  const renderComponent = () => {
    if (publicKey && missingID) {
      return <CRWalletConnected />;
    }

    if (publicKey && !missingID) {
      return <LeaderboardContent />;
    }

    if (!publicKey) {
      return <CRConnectWallet />;
    }
  };

  return <Box sx={container}>{renderComponent()}</Box>;
};

export default Quest;
