import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useAtom } from "jotai";

import { currentWallet } from "lib/store";
import { saveUserWallet } from "lib/axios/requests/users/saveUserWallet";

import { textStyle, container, title, roseDropTextStyle } from "./styles";

const RoseDropButton = styled(Button)`
  max-height: 3vmax;
  background-color: #076936;
  line-height: 0 !important;
  padding: 2.4vmax 3vmax;
  border-radius: 3px;
  color: #fff;
  border: solid 1px #000;
  &:focus {
    background-color: #076936;
  }

  &:hover {
    background-color: #1a1f2e;
  }

  @media (min-width: 769px) {
    padding: 2vmax 3.3vmax;
  }
`;

export const CRWalletConnected = () => {
  const [publicKey] = useAtom(currentWallet);
  const [walletSaved, setWalletSaved] = React.useState(false);
  const [error, setError] = React.useState(false);

  const sendWalletToWaitlist = React.useCallback(async () => {
    try {
      if (walletSaved) return;
      setError(false);
      await saveUserWallet(publicKey);
      setWalletSaved(true);
    } catch (err) {
      setWalletSaved(false);
      setError(true);
    }
  }, [publicKey, walletSaved]);

  return (
    <>
      <Box sx={container}>
        <Typography
          sx={title}
          variant="h2"
        >{`Welcome Home, Stranger`}</Typography>

        <Typography variant="h3" sx={textStyle}>
          {`We’re Hellbenders Motorcycle Club, an outlaw biker gang of amphibious superheroes on an epic adventure beyond time, space and the death-grip of global civilization.`}
        </Typography>
        <Typography variant="h3" sx={textStyle}>
          {`There are no rules to follow here. No terms of service. No privacy statements. There’s not even any intellectual f*cking property.`}
        </Typography>
        <Typography variant="h3" sx={textStyle}>
          {`To enter the Slipstream, you’ll need at least a little something in your wallet recognized by the Hellbenders.`}
        </Typography>
        <Typography variant="h3" sx={{ ...textStyle, marginBottom: "2vmax" }}>
          {`You can go out and hunt something down, or sign up for the next airdrop!
`}
        </Typography>

        <RoseDropButton
          sx={{
            "&.Mui-disabled": {
              backgroundColor: "#076936 !important",
              color: "#FFF !important",
            },
          }}
          disabled={walletSaved}
          onClick={sendWalletToWaitlist}
        >
          <Typography sx={roseDropTextStyle}>
            {walletSaved
              ? `CONGRATS! YOU'RE SIGNED UP FOR THE NEXT AIRDROP`
              : `AIRDROP ME!`}
          </Typography>
        </RoseDropButton>

        {error && (
          <>
            <Typography variant="h3" sx={{ ...textStyle, color: "#D35E01" }}>
              {"Sorry, Stranger"}
            </Typography>
            <Typography
              variant="h3"
              sx={{ ...textStyle, color: "#D35E01" }}
            >{`Our machine elves didn't catch your wallet address`}</Typography>
            <Typography variant="h3" sx={{ ...textStyle, color: "#D35E01" }}>
              {"Try again in a bit."}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};
